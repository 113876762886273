import imprt from "../images/import.svg";
import exprt from "../images/export.svg";
import stockMarker from "../images/stock-marker.svg";
import {
  UserInfo,
  dogeData,
  ethData,
  qrData,
  tronData,
  usdtData,
} from "../contexts/pageContext";
import { useContext, useState, useEffect } from "react";
import btc from "../images/BTC.svg";
import eth from "../images/eth.svg";
import close from "../images/close.svg";
import copy from "../images/copy.svg";
import doge from "../images/doge.svg";
import usdt from "../images/Tether.svg";
import bt from "../images/bt.svg";
import imgExport from "../images/imgexport.svg";
import { motion, warning } from "framer-motion";
import load from "../images/load.gif";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import arrLeft from "../images/arrLeft.svg";
import arrRight from "../images/arrRight.svg";
import danger from "../images/danger.svg";
import warner from "../images/warning.svg";
import AAPLComp from "./aapl";
import GOOGComp from "./goog";
import TSLAComp from "./tsla";
import tron from "../images/tron.svg";

const StockDash = () => {
  const [userData, setUserData] = useContext(UserInfo);
  const [dataSet, setDataSet] = useState([]);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [depoPopup, setDepoPopup] = useState(false);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState("No");
  const [file, setFile] = useState(null);
  const [lowbal, setLowBal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nameFile, setNameFile] = useState(false);
  const [tradeAmount, setTradeAmount] = useState("0");
  const [walletAddy, setWalletAddy] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [lowBalance, setLowBalance] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [codeImage, setCodeImage] = useContext(qrData);
  const [ethImg, setEthImg] = useContext(ethData);
  const [dogeImg, setDogeImg] = useContext(dogeData);
  const [usdtImg, setUsdtImg] = useContext(usdtData);
  const [tronImg, setTronImg] = useContext(tronData);
  // stocks buy states
  const [featBuy, setFeatBuy] = useState(false);
  const [featName, setFeatName] = useState("");
  const [featCycle, setFeatCycle] = useState("");
  const [featPrice, setFeatPrice] = useState("");
  const [featProfit, setFeatProfit] = useState("");
  const [featInfo, setFeatInfo] = useState("");
  const [count, setCount] = useState(0);
  const strPrice = parseInt(featPrice);
  const [depoPopup1, setDepoPopup1] = useState(false);

  const handleBuyClick = (e) => {
    setFeatBuy(true);
    const parent = e.target.parentElement;
    const parentParent = parent.parentElement;
    const elements = parentParent.children;
    const name = elements[0].innerHTML;
    const price = elements[1].innerHTML;
    const profit = elements[2].innerHTML;
    const info = elements[3].innerHTML;
    const cycle = elements[4].innerHTML;
    setFeatName(name);
    setFeatPrice(price);
    setFeatProfit(profit);
    setFeatInfo(info);
    setFeatCycle(cycle);
  };

  //days increase and decrease
  const handleDecrease = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleIncrease = () => {
    setCount(count + 1);
  };

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);

  // addy copy
  const handleCopyClick = (e) => {
    const parent = e.target.parentElement;
    const elements = parent.children;
    const addy = elements[0];

    // Check if the addy element is an input element
    if (addy && addy instanceof HTMLInputElement) {
      addy.select();
      addy.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(addy.value);
    }
  };

  //date and time
  // For todays date;
  Date.prototype.today = function () {
    return (
      (this.getDate() < 10 ? "0" : "") +
      this.getDate() +
      "/" +
      (this.getMonth() + 1 < 10 ? "0" : "") +
      (this.getMonth() + 1) +
      "/" +
      this.getFullYear()
    );
  };

  // For the time now
  Date.prototype.timeNow = function () {
    return (
      (this.getHours() < 10 ? "0" : "") +
      this.getHours() +
      ":" +
      (this.getMinutes() < 10 ? "0" : "") +
      this.getMinutes() +
      ":" +
      (this.getSeconds() < 10 ? "0" : "") +
      this.getSeconds()
    );
  };

  const newDate = new Date();
  const date = newDate.today();
  const time = newDate.timeNow();

  //document data
  const userName = userData.name;
  const userUid = userData.uid;
  const transType = "Deposit";
  const transState = "Pending";
  const imageName = userName + date;

  const sumbmitImg = () => {
    setLoading("Yes");
    const metadata = {
      contentType: "image/jpeg, image/png",
      name: imageName,
    };
    const storageRef = ref(storage, imageName, metadata);

    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(storageRef).then((url) => {
        const transactionDoc = collection(db, "transactions");
        const docData = {
          name: userName,
          uid: userUid,
          transactionType: transType,
          transactionState: transState,
          tag: "Stocks",
          amount: amount,
          date: date,
          url: url,
          time: time,
        };
        addDoc(transactionDoc, docData).then(() => {
          setLoading("No");
          const confirm = document.getElementById("confirm");
          confirm.style.display = "block";
          // alert('Submitted')
        });
      });
    });
  };
  // Convert Totalbalance and amount to integer
  const totalBalanceAsString = userData.Totalbal;
  const amountAsString = amount;
  const tradeAmountAsString = tradeAmount;
  const StockDepositAsString = userData.StockDeposit;

  const totalBalance = parseInt(totalBalanceAsString);
  const amountAsInt = parseInt(amountAsString);
  const tradeAmountAsInt = parseInt(tradeAmountAsString);
  const StockDepositAsInt = parseInt(StockDepositAsString);
  const witdrawTry = parseInt(withdrawAmount);

  const transfer = () => {
    if (amountAsInt > totalBalance || totalBalance === 0) {
      setLowBal(true);
    } else if (totalBalance > amountAsInt || totalBalance == amountAsInt) {
      setLowBal(false);
      const transactionDoc = collection(db, "transactions");
      const docData = {
        name: userName,
        uid: userUid,
        transactionType: transType,
        transactionState: transState,
        tag: "Stocks",
        amount: amountAsInt,
        date: date,
        time: time,
      };
      addDoc(transactionDoc, docData).then(() => {
        setSuccess(true);
      });
    }
  };

  //submit withdrawal
  const submitWithdrawal = () => {
    if (witdrawTry == 0 || witdrawTry > totalBalance) {
      setLowBalance(true);
    } else if (totalBalance > witdrawTry || totalBalance === witdrawTry) {
      setLoading("Yes");
      const transactionDoc = collection(db, "transactions");
      const docData = {
        name: userName,
        uid: userUid,
        transactionType: "Withdrawal",
        transactionState: transState,
        tag: "Stocks",
        amount: withdrawAmount,
        date: date,
        time: time,
        addy: walletAddy,
      };
      addDoc(transactionDoc, docData).then(() => {
        setLoading("No");
        setUpgrade(true);
      });
    }
  };

  return (
    <>
      <div className=" text-white">
        <div className=" w-full h-[185px] bg-[#1e1e1e] md:h-auto rounded-[15px] p-6 flex relative flex-col md:flex-row justify-between">
          <span className=" flex flex-col">
            <span className=" flex items-start ">
              <span className="  h-[32px] w-[32px] md:h-[45px] md:w-[45px] mr-2 rounded-[50%] bg-[#03AAC7] flex justify-center items-center">
                <img src={stockMarker} alt="" />
              </span>
              <div className=" flex flex-col mt-2 md:mt-3 w-full">
                <p className=" font-Lato md:text-[24px] font-normal text-sm md:text-base">
                  Stocks
                </p>
                <div className=" mt-5 w-full hidden md:flex flex-row justify-between md:space-x-8">
                  <span className=" -ml-8 md:ml-0 flex flex-col">
                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                      Total balance
                    </p>
                    <p className=" font-Lato font-normal text-sm md:text-xl">
                      {"$" + userData.StockTotalbal + ".00"}
                    </p>
                  </span>
                  <span className=" flex flex-col">
                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                      Deposit
                    </p>
                    <p className=" font-Lato font-normal text-sm md:text-xl">
                      {"$" + userData.StockDeposit + ".00"}
                    </p>
                  </span>
                  <span className=" flex flex-col">
                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                      Profit
                    </p>
                    <p className=" font-Lato font-normal text-sm md:text-xl">
                      {"$" + userData.StockProfits + ".00"}
                    </p>
                  </span>
                </div>
              </div>
            </span>
          </span>
          <span className=" mt-3 md:mt-0 absolute top-3 right-6 flex flex-row space-x-6 md:space-x-10">
            <label
              htmlFor=""
              onClick={() => {
                setDepoPopup(true);
              }}
              className=" flex flex-col items-center font-Lato font-semibold text-sm"
            >
              <button className=" bg-[#03AAC7] h-[32px] w-[32px] md:h-[45px] md:w-[45px] flex justify-center items-center mb-1 rounded-[50%]">
                <img src={imprt} alt="" />
              </button>
              Deposit
            </label>
            <label
              htmlFor=""
              onClick={() => {
                setWithdrawPopup(true);
              }}
              className=" flex flex-col items-center font-Lato font-semibold text-sm"
            >
              <button className=" bg-[#03AAC7] h-[32px] w-[32px] md:h-[45px] md:w-[45px] flex justify-center items-center mb-1 rounded-[50%]">
                <img src={exprt} alt="" />
              </button>
              Withdraw
            </label>
          </span>

          <div className="  border-t pt-3 w-full flex md:hidden border-[#EAEBF01A] flex-row justify-between ">
            <span className=" md:ml-0 flex flex-col">
              <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                Total balance
              </p>
              <p className=" font-Lato font-normal text-sm md:text-xl">
                {"$" + userData.StockTotalbal + ".00"}
              </p>
            </span>
            <span className=" flex flex-col">
              <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                Deposit
              </p>
              <p className=" font-Lato font-normal text-sm md:text-xl">
                {"$" + userData.StockDeposit + ".00"}
              </p>
            </span>
            <span className=" flex flex-col">
              <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">
                Profit
              </p>
              <p className=" font-Lato font-normal text-sm md:text-xl">
                {"$" + userData.StockProfits + ".00"}
              </p>
            </span>
          </div>
        </div>

        <div className=" w-full flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between mt-[1.5em]">
          <div className=" w-full md:w-1/3 h-[180px] p-2 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
            <AAPLComp />
          </div>

          <div className=" w-full md:w-1/3 h-[180px] p-2 border rounded-[15px] md:mx-4 bg-[#1E1E1E33] border-[#EAEBF01A]">
            <GOOGComp />
          </div>

          <div className=" w-full md:w-1/3 h-[180px] p-2 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
            <TSLAComp />
          </div>
        </div>

        <div className="w-full mt-[1.5em] p-4 border overflow-auto rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
          <table className="border-collapse  border-b w-full border-[#EAEBF01A] mt-3">
            <thead>
              <tr className=" text-[#a7a7a7] text-sm text-left font-Lato font-medium">
                <th className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Stock Name
                </th>
                <th className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Minimum selling($)
                </th>
                <th className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Profit
                </th>
                <th className="px-2 py-4 border-b border-[#EAEBF01A]">Cycle</th>
                <th className="px-2 py-4 border-b border-[#EAEBF01A]"></th>
              </tr>
            </thead>
            <tbody className=" font-Open-Sans text-sm font-normal">
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  L&K CBD
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">5000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  15.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden"></td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Weekly
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>

              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Canopy Growth (CGC)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">10</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  25.10%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Offers diversification and mitigates risk, Well-positioned for
                  eventual US market opening, Established brand with significant
                  recognition.
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Weekly
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>

              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Operatrades Forex Trade(AFXT)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">500</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  1.10%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Alstonglobal Forex Day Trading (AFXDT) a Roy.j.Camillaâ€™s
                  forex trade group.This group consist of different traders
                  employed by Alstonglobal for a common goal of making good
                  return on investment for the company and its investors. This
                  group was found in 2012 as a Roy.j.Camilla private trading
                  company, trading for him and his investor colleagues until
                  2020 when the company was integrated into a public limited
                  liability company.Thereafter,it went public and globally for
                  investors to invest for the collective aim of controlling the
                  market using its strength of capital comparative as advantage
                  to manipulate global forex market for the benefit of its
                  investors. The Alstonglobal forex trading company current
                  market capitalization is over 665 million dollar.It's a great
                  company to buy into as it's projected to hit 1 billion dollar
                  market capitalization before the end of 2021. As its market
                  capitalization increases so would return on investment(ROI)
                  increase for its investors. Minimum investment-$200 Contract
                  duration-1year Withdrawals-Saturday & Sundayâ€™s Return on
                  investment(ROI)-1.10% working days
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Weekly
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>

              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Green Thumb Industries (GTI)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">20</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  20.10%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Green Thumb Industries (GTI) Capitalizes on a mature and
                  established market, it boasts consistent profitability. it
                  cultivates a loyal customer base
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Weekly
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>

              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Archer-Daniels Midland Company(ADM)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">5000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  35.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  The Archer-Daniels-Midland Company, commonly known as ADM, is
                  an American multinational food processing and commodities
                  trading corporation which produces Corn Syrup, High fructose
                  corn syrup, Feed, Ethanol and Bioenergy. ADM was found in 1902
                  and headquartered in Chicago, Illinois. Stock price: ADM
                  (NYSE) US$58.25 CEO: Juan Ricardo Luciano Headquarters:
                  Chicago, Illinois. ADM was Founded 1902, Minneapolis,
                  Minnesota, United States. ADM currently at $64.66 billion USD
                  market capitalization. Subsidiaries: ADM Investor Services,
                  Inc., Wild, Archer Daniels Midland Singapore Pte Ltd.
                  Founders: George A. Archer and John W. Daniels. Investment
                  features: * 35% ROI everyone 3months * 1year contract duration
                  with automatic reinvestment. * Minimum investment $5000 *
                  profit withdrawal every 3months
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] ">
                  Three Months
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">BAYN</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">10000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  1.60%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  BAYN is DAX Component Industry which deals in Life sciences
                  and Pharmaceuticals Chemicals BAYN was Founded 1 August 1863
                  by Friedrich Bayer. BAYN is Headquartered in Leverkusen,
                  Germany. Chief executive officer(CEO)-Werner Baumann.
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">Daily</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Diamond Shipping Group Inc.(DSD)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">15000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  50.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Diamond Shipping Group Inc. (DSG) is a shipping company
                  legally incorporated in the Marshall Islands in 2007 with
                  headquarters in Greenwich, Connecticut, United States .
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Three Months
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Clean Energy Fuels Corp.(CEF)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">25000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  70.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Clean Energy Fuels Corp.founded in 1997,Clean Energy Fuels is
                  a producer of renewable natural gas. That means they create
                  natural gas from decomposing organic waste, rather than
                  extracting it from underground deposits.
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Six Months
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Double Hall Tankers Inc.(DHT)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">20000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  2.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Double hall Tankers inc. (DHT)founded in 2005 headquartered in
                  Bermuda DHT was formed and listed on the New York Stock
                  Exchange in 2005.DHT Holdings, Inc. is an independent crude
                  oil tanker company. The Companyâ€™s fleet trades
                  internationally and consists of 27 crude oil tankers in the
                  very large crude carrier (VLCC) segment. The Company has all
                  functions in-house and operates through integrated management
                  companies in Monaco, Singapore and Oslo, Norway. Svein Moxnes
                  Harfjeld and Trygve Preben Munthe Are Co-Chief Executive Of
                  the company, the company boasts of a market capitalization of
                  $USD975.3million dollars.
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Working Days
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Helix Energy Solutions Inc(HES)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">40000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  35.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  Helix Energy Solutions Inc., known as Cal Dive International
                  prior to 2006,it is an American oil and gas services company
                  headquartered in Houston, Texas. It was founded in 1980, the
                  company is a global provider of offshore services in well
                  intervention
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Monthly
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  QEP Resources Inc.(WEPR)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">50000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  48.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  QEP Resources Inc QEP Resources, Inc. operates as an
                  independent natural gas, oil exploration, and production
                  company. The Company has its headquarters in Denver, Colorado
                  United States. The company was founded in 1922,QEP also
                  gathers, compresses, and treats.
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Three Months
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Scottâ€™S Miracle-Gro Company(SMG)
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">5000</td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] text-[#0CD16B]">
                  80.00%
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A] hidden">
                  The Scotts Miracle-Gro Company is an American multinational
                  corporation headquartered in Marysville, Ohio, founded by
                  Orlando M. Scott in 1868 as a premium seed company for the
                  U.S. agricultural industry.
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  Six Months
                </td>
                <td className="px-2 py-4 border-b border-[#EAEBF01A]">
                  <button
                    onClick={handleBuyClick}
                    className=" rounded-[20px] px-4 py-2 bg-[#03AAC7] text-white text-center"
                  >
                    Buy
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {featBuy && (
        <div className=" w-full h-[100vh] fixed z-[999] px-5 top-0 left-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
          <div className=" w-full md:w-[450px] rounded-[30px] shadow p-5 bg-[#2b2b2b] relative text-sm font-Lato font-medium items-center space-y-">
            <img
              src={close}
              onClick={() => {
                setFeatBuy(false);
              }}
              className=" absolute top-3 right-4 h-6 w-6"
              alt=""
            />
            <p className=" text-base text-white text-left font-bold mb-3">
              {featName}
            </p>
            <div className=" flex flex-row justify-between w-full mb-3">
              <p className=" text-[#FFFFFFCC]">
                <span className=" text-white">Price: </span>
                {"$" + featPrice}
              </p>
              <p className=" text-[#0CD16B]">
                <span className=" text-[#FFFFFFCC]">Profit:</span> {featProfit}
              </p>
              <p className="text-[#FFFFFFCC]">
                <span className="text-[#FFFFFF]">Cycle: </span>
                {featCycle}
              </p>
            </div>
            <p className=" h-[90px] text-[#a7a7a7] w-full overflow-y-scroll scrollbar-hide mb-6 scrollbar">
              {featInfo}
            </p>
            <label className="w-full  font-Open-Sans text-white text-sm font-medium">
              How Many Units?
              <div className="w-full h-[44px] mt-2 rounded-md bg-transparent relative">
                <input
                  type="number"
                  value={count}
                  min={0}
                  max={100}
                  step={1}
                  readOnly
                  className=" w-full h-[44px] text-center bg-[#1e1e1e] rounded-[30px]"
                />
                <img
                  src={arrLeft}
                  onClick={handleDecrease}
                  className="absolute top-[12px] left-3"
                  alt=""
                />
                <img
                  src={arrRight}
                  className="absolute top-[12px]  right-3"
                  onClick={handleIncrease}
                  alt=""
                />
              </div>
            </label>
            <label className="w-full text-white font-Open-Sans">
              Amount
              <input
                type="number"
                value={count * strPrice}
                readOnly
                className=" mt-2 w-full text-center h-[44px] rounded-[30px] bg-[#1e1e1e] p-2.5 border"
              />
            </label>
            <button
              onClick={() => {
                setDepoPopup1(true);
              }}
              className=" px-4 py-2 mt-3 block ml-auto rounded-[20px] font-Anuphan text-sm text-white font-medium bg-[#03AAC7]"
            >
              Buy
            </button>
          </div>
        </div>
      )}
      {depoPopup1 && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto"
        >
          <div className="h-[85vh] ">
            <div className=" w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] ">
              <div className=" flex flex-row justify-between border-b p-6 border-[#EAEBF01A]">
                <span className="flex flex-col">
                  <p className=" font-Lato font-medium text-xl">Deposit</p>
                  <p className=" font-Lato font-normal text-xs text-[#FFFFFFCC]">
                    Send only to one of the following address
                  </p>
                </span>
                <img
                  src={close}
                  onClick={() => {
                    setDepoPopup1(false);
                  }}
                  alt=""
                />
              </div>
              <div className=" w-full px-6 md:px-6 pt-6 pb-3">
                <div className=" border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed">
                  <input
                    type="text"
                    placeholder="$0.00"
                    value={count * strPrice}
                    className=" w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]"
                  />
                  <p className=" pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]">
                    Enter Amount
                  </p>
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 space-y-2">
                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={btc} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">BTC</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"bc1qj6wfphulvuexps3s3mv0wysfw40ng5gmkymyrg"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setCodeImage(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={eth} alt="" />
                    <p className=" font-Open-Sans text-sm md:text-sm font-normal">
                      ETH
                    </p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"0x59e4Ad35E1AF028282299b15fb1C5d0190BB1534"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setEthImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={doge} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">DOGE</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"DFZfKD5UztJQ9wkKFCg1otS22Hp14bg5Gm"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setDogeImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={usdt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      USDT(TRC20)
                    </p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setUsdtImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={tron} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">Tron</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setTronImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={bt} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">TFR</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"Contact support for assistance"}
                      className=" w-[100%] text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                  <button className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]">
                    Support
                  </button>
                </div>
              </div>
              <div className=" py-3 w-full px-6 md:px-6">
                <div className="p-6 bg-[#1e1e1e] text-white w-full flex flex-col justify-center items-center rounded-[20px]">
                  <button
                    type="file"
                    className=" w-[60px] h-[60px] rounded-[50%] bg-transparent flex justify-center items-center relative"
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setNameFile(true);
                      }}
                      className=" w-[60px] h-[60px] cursor-pointer z-50 opacity-0"
                      name=""
                      id=""
                    />
                    <img
                      src={imgExport}
                      className=" absolute z-10 top-3 left-[10px]"
                      alt=""
                    />
                  </button>
                  <p className=" font-Lato font-medium text-center text-base md:text-xl mt-1">
                    Click here to upload proof of payment
                  </p>
                  {nameFile && (
                    <p className=" font-Lato font-medium text-xs md:text-sm text-[[#FFFFFFCC] mt-1">
                      {imageName}
                    </p>
                  )}
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 py-3">
                <button
                  onClick={sumbmitImg}
                  className=" py-2 px-4 bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto block"
                >
                  {loading === "No" && "Submit"}
                  {loading === "Yes" && <img className=" w-6 h-6" src={load} />}
                </button>
                <p
                  id="confirm"
                  className=" ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]"
                >
                  *Your transaction has been received and will be processed
                  shortly
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {depoPopup && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto"
        >
          <div className="h-[85vh] ">
            <div className=" w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] ">
              <div className=" flex flex-row justify-between border-b p-6 border-[#EAEBF01A]">
                <span className="flex flex-col">
                  <p className=" font-Lato font-medium text-xl">Deposit</p>
                  <p className=" font-Lato font-normal text-xs text-[#FFFFFFCC]">
                    Send only to one of the following address
                  </p>
                </span>
                <img
                  src={close}
                  onClick={() => {
                    setDepoPopup(false);
                  }}
                  alt=""
                />
              </div>
              <div className=" w-full px-6 md:px-6 pt-6 pb-3">
                <div className=" border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed">
                  <input
                    type="text"
                    placeholder="$0.00"
                    onKeyUp={(e) => {
                      setAmount(e.target.value);
                    }}
                    className=" w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]"
                  />
                  <p className=" pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]">
                    Enter Amount
                  </p>
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 space-y-2">
                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={btc} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">BTC</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"bc1qj6wfphulvuexps3s3mv0wysfw40ng5gmkymyrg"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setCodeImage(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={eth} alt="" />
                    <p className=" font-Open-Sans text-sm md:text-sm font-normal">
                      ETH
                    </p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"0x59e4Ad35E1AF028282299b15fb1C5d0190BB1534"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setEthImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={doge} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">DOGE</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"DFZfKD5UztJQ9wkKFCg1otS22Hp14bg5Gm"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setDogeImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={usdt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      USDT(TRC20)
                    </p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setUsdtImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={tron} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">Tron</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y"}
                      className=" w-[90%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                    <img src={copy} onClick={handleCopyClick} alt="" />
                  </div>
                  <button
                    onClick={() => setTronImg(true)}
                    className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]"
                  >
                    QR Code
                  </button>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={bt} alt="" />
                    <p className=" font-Open-Sans text-sm font-normal">TFR</p>
                  </span>
                  <div className=" relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"Contact support for assistance"}
                      className=" w-[100%] text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                  <button className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]">
                    Support
                  </button>
                </div>
              </div>
              <div className=" py-3 w-full px-6 md:px-6">
                <div className="p-6 bg-[#1e1e1e] text-white w-full flex flex-col justify-center items-center rounded-[20px]">
                  <button
                    type="file"
                    className=" w-[60px] h-[60px] rounded-[50%] bg-transparent flex justify-center items-center relative"
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setNameFile(true);
                      }}
                      className=" w-[60px] h-[60px] cursor-pointer z-50 opacity-0"
                      name=""
                      id=""
                    />
                    <img
                      src={imgExport}
                      className=" absolute z-10 top-3 left-[10px]"
                      alt=""
                    />
                  </button>
                  <p className=" font-Lato font-medium text-center text-base md:text-xl mt-1">
                    Click here to upload proof of payment
                  </p>
                  {nameFile && (
                    <p className=" font-Lato font-medium text-xs md:text-sm text-[[#FFFFFFCC] mt-1">
                      {imageName}
                    </p>
                  )}
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 py-3">
                <button
                  onClick={sumbmitImg}
                  className=" py-2 px-4 bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto block"
                >
                  {loading === "No" && "Submit"}
                  {loading === "Yes" && <img className=" w-6 h-6" src={load} />}
                </button>
                <p
                  id="confirm"
                  className=" ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]"
                >
                  *Your transaction has been received and will be processed
                  shortly
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {withdrawPopup && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto"
        >
          <div className="h-[85vh] ">
            <div className=" w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] ">
              <div className=" flex flex-row justify-between border-b p-6 border-[#EAEBF01A]">
                <span className="flex flex-col">
                  <p className=" font-Lato font-medium text-xl">Withdraw</p>
                  <p className=" font-Lato font-normal text-xs text-[#FFFFFFCC]">
                    Please double-check the recipient's wallet address before
                    withdrawing your funds.
                  </p>
                </span>
                <img
                  src={close}
                  onClick={() => {
                    setWithdrawPopup(false);
                  }}
                  alt=""
                />
              </div>
              <div className=" w-full px-6 md:px-6 pt-6 pb-3">
                <div className=" border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed">
                  <input
                    type="text"
                    placeholder="$0.00"
                    onKeyUp={(e) => {
                      setWithdrawAmount(e.target.value);
                    }}
                    className=" w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]"
                  />
                  <p className=" pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]">
                    Enter Amount
                  </p>
                </div>
              </div>

              <div className=" w-full px-6 md:px-6 space-y-2">
                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={btc} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      BTC
                    </p>
                  </span>
                  <div className=" relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      type="text"
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={eth} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      ETH(BEP 20)
                    </p>
                  </span>
                  <div className=" relative w-[60%] md:w-[80%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={doge} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      DOGE
                    </p>
                  </span>
                  <div className=" relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={usdt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      USDT
                    </p>
                  </span>
                  <div className=" relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      onKeyUp={(e) => {
                        setWalletAddy(e.target.value);
                      }}
                      placeholder="Paste wallet...."
                      className=" font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                </div>

                <div className=" w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between">
                  <span className=" flex items-center space-x-2">
                    <img src={bt} alt="" />
                    <p className=" font-Open-Sans text-xs md:text-sm font-normal">
                      TFR
                    </p>
                  </span>
                  <div className=" relative w-[50%] md:w-[70%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]">
                    <input
                      type="text"
                      readOnly
                      value={"Contact support for assistance"}
                      className=" w-[100%] text-[#FFFFFFCC] bg-transparent"
                    />
                  </div>
                  <button className=" text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]">
                    Support
                  </button>
                </div>
              </div>
              <div className=" w-full px-6 md:px-6 py-3">
                <button
                  onClick={submitWithdrawal}
                  className=" w-full flex justify-center items-center h-[45px] bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto"
                >
                  {loading === "No" && "Process Withdrawal"}
                  {loading === "Yes" && <img className=" w-6 h-6" src={load} />}
                </button>
                <p
                  id="withdraw"
                  className=" ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]"
                >
                  *Your transaction has been received and will be processed
                  shortly
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {lowBalance && (
        <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto">
          <div className=" px-10 py-8 bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center">
            <img
              src={close}
              onClick={() => {
                setLowBalance(false);
              }}
              className=" absolute right-4 top-3"
              alt=""
            />
            <img src={danger} alt="" />
            <p className=" mt-3 text-center font-Lato font-medium text-2xl ">
              Insufficient balance
            </p>
            <p className=" mt-3 text-center font-Lato font-normal text-base w-[240px]">
              You do not have enough funds in your wallet to complete this
              withdrawal. Please deposit more funds or reduce the amount you are
              withdrawing.
            </p>
          </div>
        </div>
      )}
      {upgrade && (
        <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto">
          <div className=" px-10 py-8 bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center">
            <img
              src={close}
              onClick={() => {
                setUpgrade(false);
              }}
              className=" absolute right-4 top-3"
              alt=""
            />
            <img src={warner} alt="" />
            <p className=" mt-3 text-center font-Lato font-medium text-2xl ">
              Commision Fee
            </p>
            <p className=" mt-3 text-center font-Lato font-normal text-base w-[240px]">
              To withdraw funds, you need to pay a commision fee.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default StockDash;
