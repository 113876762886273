import tron from '../images/tron.svg';
import btc from '../images/BTC.svg';
import eth from '../images/eth.svg';
import close from '../images/close.svg';
import copy from '../images/copy.svg';
import bt from '../images/bt.svg';
import imgExport from '../images/imgexport.svg';
import doge from '../images/doge.svg';
import usdt from '../images/Tether.svg';
import { UserInfo, dogeData, ethData, qrData, tronData, usdtData } from '../contexts/pageContext';
import { useContext, useState } from 'react';
import load from '../images/load.gif';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { motion } from 'framer-motion';


const Packages = () => {

    const [plan, setPlan] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState('No');
    const [userData, setUserData] = useContext(UserInfo);
    const [depoPopup, setDepoPopup] = useState(false);
    const [file, setFile] = useState(null);
    const [nameFile, setNameFile] = useState(false);
    const [codeImage, setCodeImage] = useContext(qrData);
    const [ethImg, setEthImg] = useContext(ethData);
    const [dogeImg, setDogeImg] = useContext(dogeData);
    const [usdtImg, setUsdtImg] = useContext(usdtData);
    const [tronImg, setTronImg] = useContext(tronData);


    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);

    const handleCopyClick = (e) => {
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
      
        // Check if the addy element is an input element
        if (addy && addy instanceof HTMLInputElement) {
          addy.select();
          addy.setSelectionRange(0, 99999);
          navigator.clipboard.writeText(addy.value);
        }
    };

    //date and time
    // For todays date;
    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }

    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    
    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const imageName = userName + date;

    const sumbmitImg = ()=>{
        setLoading('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName,
        };
        const storageRef = ref(storage, imageName, metadata);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                const transactionDoc = collection(db, 'transactions');
                const docData = {
                    name: userName,
                    uid:userUid,
                    transactionType:"Plan Purchase",
                    transactionState:'Pending',
                    plan:plan,
                    tag:'Plan',
                    amount:amount,
                    date:date,
                    url:url,
                    time:time,
                }
                addDoc(transactionDoc, docData)
                .then(()=>{
                    setLoading('No');
                    const confirm = document.getElementById('confirm');
                    confirm.style.display = 'block';
                    // alert('Submitted')
                });
            });
        });
    };

    return ( 
        <>
        <div className=" text-white">
            <div className=" flex justify-between flex-col md:flex-row w-full space-y-[1.5em] md:space-y-0">
                <div className=" w-full md:w-1/3 bg-[#1E1E1E] rounded-[15px] p-6">
                    <p className=" font-Lato font-medium text-xl">Starter Package</p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Minimum deposit: <span className=" text-white font-semibold text-sm">$100.00</span></p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Maximum deposit: <span className=" text-white font-semibold text-sm">$1,000.00</span></p>
                    <span className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Access to the trading platform and basic features</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">ROI: 20%</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">24/7 customer support</p>
                    </span>
                    <button onClick={()=>{setDepoPopup(true); setPlan('Starter')}} className=" text-center px-6 py-2 bg-[#03AAC7] mt-12 text-sm font-Open-Sans rounded-[20px]">Purchase plan</button>
                </div>

                <div className=" w-full md:w-1/3 bg-[#1E1E1E] rounded-[15px] p-6 md:mx-[3%]">
                    <p className=" font-Lato font-medium text-xl">Bronze Package</p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Minimum deposit: <span className=" text-white font-semibold text-sm">$1,000.00</span></p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Maximum deposit: <span className=" text-white font-semibold text-sm">$5,000.00</span></p>
                    <span className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">All of the benefits of the Starter tier, plus:</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">ROI: 35%</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Dedicated account manager</p>
                    </span>
                    <button onClick={()=>{setDepoPopup(true); setPlan('Bronze')}} className=" text-center px-6 py-2 bg-[#03AAC7] mt-12 text-sm font-Open-Sans rounded-[20px]">Purchase plan</button>
                </div>

                <div className=" w-full md:w-1/3 bg-[#1E1E1E] rounded-[15px] p-6">
                    <p className=" font-Lato font-medium text-xl">Silver Package</p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Minimum deposit: <span className=" text-white font-semibold text-sm">$5,000.00</span></p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Maximum deposit: <span className=" text-white font-semibold text-sm">$10,000.00</span></p>
                    <span className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">All of the benefits of the Bronze tier, plus:</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">ROI: 40%</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Weekly market analysis webinar</p>
                    </span>
                    <button onClick={()=>{setDepoPopup(true); setPlan('Silver')}} className=" text-center px-6 py-2 bg-[#03AAC7] mt-12 text-sm font-Open-Sans rounded-[20px]">Purchase plan</button>
                </div>

            </div>
            <div className=" flex justify-between flex-col md:flex-row mt-[1.5em] space-y-[1.5em] md:space-y-0 w-full">

                <div className=" w-full md:w-1/3 bg-[#1E1E1E] rounded-[15px] p-6">
                    <p className=" font-Lato font-medium text-xl">Gold Package</p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Minimum deposit: <span className=" text-white font-semibold text-sm">$10,000.00</span></p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Maximum deposit: <span className=" text-white font-semibold text-sm">$50,000.00</span></p>
                    <span className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">All of the benefits of the Silver tier, plus: </p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">ROI: 50%</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Monthly one-on-one coaching session with a trading expert</p>
                    </span>
                    <button onClick={()=>{setDepoPopup(true); setPlan('Gold')}} className=" text-center px-6 py-2 bg-[#03AAC7] mt-12 text-sm font-Open-Sans rounded-[20px]">Purchase plan</button>
                </div>

                <div className=" w-full md:w-1/3 bg-[#1E1E1E] rounded-[15px] p-6 md:mx-[3%]">
                    <p className=" font-Lato font-medium text-xl">Platinum Package</p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Minimum deposit: <span className=" text-white font-semibold text-sm">$50,000.00</span></p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Maximum deposit: <span className=" text-white font-semibold text-sm">$100,000.00</span></p>
                    <span className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">All of the benefits of the Gold tier, plus:</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">ROI: 60%</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Access to a dedicated trading concierge</p>
                    </span>
                    <button onClick={()=>{setDepoPopup(true); setPlan('Platinum')}} className=" text-center px-6 py-2 bg-[#03AAC7] mt-12 text-sm font-Open-Sans rounded-[20px]">Purchase plan</button>
                </div>

                <div className=" w-full md:w-1/3 bg-[#1E1E1E] rounded-[15px] p-6">
                    <p className=" font-Lato font-medium text-xl">Diamond Package</p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Minimum deposit: <span className=" text-white font-semibold text-sm">$100,000.00</span></p>
                    <p className=" font-sans font-normal text-[#FFFFFF99] mt-2 text-sm">Maximum deposit: <span className=" text-white font-semibold text-sm"> over $100,000.00</span></p>
                    <span className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">All of the benefits of the Platinum tier, plus:</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">ROI: 70%</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Access to a private trading room</p>
                    </span>
                    <span  className=" flex flex-row font-Open-Sans font-normal text-sm mt-2">
                        *
                        <p className=" ml-1">Yearly VIP retreat with other Diamond members</p>
                    </span>
                    <button onClick={()=>{setDepoPopup(true); setPlan('Diamond')}} className=" text-center px-6 py-2 bg-[#03AAC7] mt-12 text-sm font-Open-Sans rounded-[20px]">Purchase plan</button>
                </div>

            </div>
            { depoPopup && <motion.div 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
            <div className='h-[85vh] '>
                <div className=' w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] '>
                    <div className=' flex flex-row justify-between border-b p-6 border-[#EAEBF01A]'>
                        <span className='flex flex-col'>
                            <p className=' font-Lato font-medium text-xl'>Deposit</p> 
                            <p className=' font-Lato font-normal text-xs text-[#FFFFFFCC]'>Send only to one of the following address</p> 
                        </span>
                        <img src={ close } onClick={()=>{setDepoPopup(false)}} alt="" />
                    </div>
                    <div className=' w-full px-6 md:px-6 pt-6 pb-3'>
                        <div className=' border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed'>
                            <input type="text" placeholder='$0.00' onKeyUp={(e)=>{setAmount(e.target.value)}} className=' w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]' />
                            <p className=' pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]'>Enter Amount</p>
                        </div>
                    </div>
                    
                    <div className=' w-full px-6 md:px-6 pt-3 pb-3'>
                        <div className=' border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed'>
                            <input type="text" placeholder='Promo Code' className=' w-[150px] text-center myInput font-Lato font-normal text-xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[40px]' />
                            <p className=' pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]'>Enter Promo Code</p>
                        </div>
                    </div>

                    <div className=' w-full px-6 md:px-6 space-y-2'>
                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ btc } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>BTC</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'bc1qj6wfphulvuexps3s3mv0wysfw40ng5gmkymyrg'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setCodeImage(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ eth } alt="" />
                                <p className=' font-Open-Sans text-sm md:text-sm font-normal'>ETH</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'0x59e4Ad35E1AF028282299b15fb1C5d0190BB1534'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setEthImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ doge } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>DOGE</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'DFZfKD5UztJQ9wkKFCg1otS22Hp14bg5Gm'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setDogeImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ usdt } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>USDT(TRC20)</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setUsdtImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ tron } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>Tron</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setTronImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ bt } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>TFR</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'Contact support for assistance'} className=' w-[100%] text-[#FFFFFFCC] bg-transparent' />
                            </div>
                            <button className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>Support</button>
                        </div>
                    </div>
                    <div className=' py-3 w-full px-6 md:px-6'>
                        <div className='p-6 bg-[#1e1e1e] text-white w-full flex flex-col justify-center items-center rounded-[20px]'>
                            <button type="file" className=' w-[60px] h-[60px] rounded-[50%] bg-transparent flex justify-center items-center relative'>
                                <input type="file" onChange={(e)=>{setFile(e.target.files[0]); setNameFile(true)}} className=" w-[60px] h-[60px] cursor-pointer z-50 opacity-0" name="" id="" />
                                    <img src={ imgExport } className=" absolute z-10 top-3 left-[10px]" alt="" />
                            </button>
                            <p className=' font-Lato font-medium text-center text-base md:text-xl mt-1'>Click here to upload proof of payment</p>
                            {nameFile && <p className=' font-Lato font-medium text-xs md:text-sm text-[[#FFFFFFCC] mt-1'>{ imageName }</p>}
                        </div>
                    </div>
                    <div className=' w-full px-6 md:px-6 py-3'>
                        <button onClick={ sumbmitImg } className=' py-2 px-4 bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto block'>
                            { loading === 'No' && 'Submit'}
                            { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                        <p id='confirm' className=' ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]'>*Your transaction has been received and will be processed shortly</p>
                    </div>
                </div>
            </div>    
        </motion.div>}
        </div>
        </>
     );
}
 
export default Packages;