import imprt from '../images/import.svg';
import exprt from '../images/export.svg';
import btcMarker from '../images/btc-marker.svg';
import { UserInfo, dogeData, ethData, qrData, tronData, usdtData } from '../contexts/pageContext';
import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import btc from '../images/BTC.svg';
import eth from '../images/eth.svg';
import close from '../images/close.svg';
import copy from '../images/copy.svg';
import doge from '../images/doge.svg';
import usdt from '../images/Tether.svg';
import bt from '../images/bt.svg';
import imgExport from '../images/imgexport.svg';
import { motion, warning } from 'framer-motion';
import load from '../images/load.gif';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import twoUser from '../images/2user.svg';
import arrLeft from '../images/arrLeft.svg';
import arrRight from '../images/arrRight.svg';
import tickCircle from '../images/tick-circle.svg';
import danger from '../images/danger.svg';
import tron from '../images/tron.svg';
import warner from '../images/warning.svg';


const CryptoDash = () => {

    const [userData, setUserData] = useContext(UserInfo);
    const [exchangeSet1, setExchangeSet1] = useState([]);
    const [exchangeSet2, setExchangeSet2] = useState([]);
    const [exchangeSet3, setExchangeSet3] = useState([]);
    const [dataSet, setDataSet] = useState([]);
    const [withdrawPopup, setWithdrawPopup] = useState(false);
    const [depoPopup, setDepoPopup] = useState(false);
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState('No');
    const [file, setFile] = useState(null);
    const [lowbal, setLowBal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [nameFile, setNameFile] = useState(false);
    const [count, setCount] = useState(0);
    const [tradePopup, setTradePopup] = useState(false);
    const [completePopup, setCompletePopup] = useState(false);
    const [tradeName, setTradeName] = useState('Bitcoin');
    const [tradeAmount, setTradeAmount] = useState('0');
    const [lowBalTrade, setLowBalTrade] = useState(false);
    const [walletAddy, setWalletAddy] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [lowBalance, setLowBalance] = useState(false);
    const [upgrade, setUpgrade] = useState(false);
    const [codeImage, setCodeImage] = useContext(qrData);
    const [ethImg, setEthImg] = useContext(ethData);
    const [dogeImg, setDogeImg] = useContext(dogeData);
    const [usdtImg, setUsdtImg] = useContext(usdtData);
    const [tronImg, setTronImg] = useContext(tronData);

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);

    useEffect(() => {
        // Define the API endpoint
        const apiUrl = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en';

        // Use Axios to fetch data from the API
        axios
          .get(apiUrl)
          .then((response) => {
            const allExchanges = response.data;
            setExchangeSet1(getRandomExchanges(allExchanges, 3));
            setExchangeSet2(getRandomExchanges(allExchanges, 3));
            setExchangeSet3(getRandomExchanges(allExchanges, 3));
            setDataSet(getRandomExchanges(allExchanges.slice(0, 15)));
          })
          .catch((error) => {
            // Handle error
            console.error('Error fetching data:', error);
          });
    }, []);

    // Function to get 4 random exchanges from the data
    const getRandomExchanges = (data, count) => {
        const shuffled = [...data].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    // Function to determine CSS class based on price change
    const getPriceChangeClass = (change) => {
        return change >= 0 ? 'positive' : 'negative';
    };

    // addy copy 
    const handleCopyClick = (e) => {
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
      
        // Check if the addy element is an input element
        if (addy && addy instanceof HTMLInputElement) {
          addy.select();
          addy.setSelectionRange(0, 99999);
          navigator.clipboard.writeText(addy.value);
        }
    };

    //date and time
    // For todays date;
    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }

    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    
    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const transType = 'Deposit';
    const transState = 'Pending';
    const imageName = userName + date;

    const sumbmitImg = ()=>{
        setLoading('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName,
        };
        const storageRef = ref(storage, imageName, metadata);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                const transactionDoc = collection(db, 'transactions');
                const docData = {
                    name: userName,
                    uid:userUid,
                    transactionType:transType,
                    transactionState:transState,
                    tag:'Crypto',
                    amount:amount,
                    date:date,
                    url:url,
                    time:time,
                }
                addDoc(transactionDoc, docData)
                .then(()=>{
                    setLoading('No');
                    const confirm = document.getElementById('confirm');
                    confirm.style.display = 'block';
                    // alert('Submitted')
                });
            });
        });
    };
    // Convert Totalbalance and amount to integer
    const totalBalanceAsString = userData.Totalbal;
    const amountAsString = amount;
    const tradeAmountAsString = tradeAmount;
    const CryptoDepositAsString = userData.CryptoDeposit;
    
    const totalBalance = parseInt(totalBalanceAsString);
    const amountAsInt = parseInt(amountAsString);
    const tradeAmountAsInt = parseInt(tradeAmountAsString);
    const CryptoDepositAsInt = parseInt(CryptoDepositAsString);
    const witdrawTry = parseInt(withdrawAmount);

    const transfer = ()=>{
        if(amountAsInt > totalBalance || totalBalance === 0){
           setLowBal(true)
        }
        else if(totalBalance > amountAsInt || totalBalance == amountAsInt){
            setLowBal(false)
            const transactionDoc = collection(db, 'transactions');
            const docData = {
                name: userName,
                uid:userUid,
                transactionType:transType,
                transactionState:transState,
                tag:'Crypto',
                amount:amountAsInt,
                date:date,
                time:time,
            }
            addDoc(transactionDoc, docData)
            .then(()=>{
                setSuccess(true)
            });
        }
    };

    //days increase and decrease
    const handleDecrease = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    const handleIncrease = () => {
        setCount(count + 1);
    };

    //get trade info
    const getTradeinfo = (e)=>{
        const btnParent = e.target.parentElement;
        const parentParent = btnParent.parentElement;
        const elements = parentParent.children;
        const nameInfo = elements[0].children;
        const nameInfoElements = nameInfo[0].children;
        const name = nameInfoElements[1].innerHTML;
        setTradeName(name);
        setTradePopup(true);
    }
    
    // upload trade information 

    const tradeTRans = ()=>{
        setLoading('Yes')
        if(tradeAmountAsInt > CryptoDepositAsInt || CryptoDepositAsInt === 0){
            setLowBalTrade(true);
            setLoading('No');
        }
        else if( CryptoDepositAsInt > tradeAmountAsInt || CryptoDepositAsInt === tradeAmountAsInt){
            const transactionDoc = collection(db, 'trades');
            const docData = {
                name: userName,
                uid:userUid,
                transactionState:'Active',
                tag:'Crypto',
                duration: count,
                amount:tradeAmount,
                date:date,
                time:time,
            }
            addDoc(transactionDoc, docData)
            .then(()=>{
                setLowBalTrade(false);
                setCompletePopup(true);
                setLoading('No');
            });
        };
    };

    //submit withdrawal
    const submitWithdrawal = ()=>{
        if(witdrawTry == 0 || witdrawTry > totalBalance){
            setLowBalance(true);
        }
        else if(totalBalance > witdrawTry || totalBalance === witdrawTry){
            setLoading('Yes');
            const transactionDoc = collection(db, 'transactions');
            const docData = {
                name: userName,
                uid:userUid,
                transactionType:'Withdrawal',
                transactionState:transState,
                tag:'Crypto',
                amount:withdrawAmount,
                date:date,
                time:time,
                addy: walletAddy,
            }
            addDoc(transactionDoc, docData)
            .then(()=>{
                setLoading('No');
                setUpgrade(true);
            });
        }
    };

    return ( 
        <>
        <div className=" text-white">
            <div className=" w-full bg-[#1e1e1e] h-[185px] md:h-auto rounded-[15px] p-6 flex relative flex-col md:flex-row justify-between">
                <span className=" flex flex-col">
                    <span className=" flex items-start ">
                        <span className="  h-[32px] w-[32px] md:h-[45px] md:w-[45px] mr-2 rounded-[50%] bg-[#03AAC7] flex justify-center items-center">
                            <img src={ btcMarker } alt="" />
                        </span>
                        <div className=' flex flex-col mt-2 md:mt-3 w-full'>
                            <p className=" font-Lato md:text-[24px] font-normal text-sm md:text-base">Cryptocurrency</p>
                            <div className=" mt-5 w-full hidden md:flex flex-row justify-between md:space-x-8">
                                <span className=" -ml-8 md:ml-0 flex flex-col">
                                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">Total balance</p>
                                    <p className=" font-Lato font-normal text-sm md:text-xl">{'$'+userData.CryptoTotalbal+'.00'}</p>
                                </span>
                                <span className=" flex flex-col">
                                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">Deposit</p>
                                    <p className=" font-Lato font-normal text-sm md:text-xl">{'$'+userData.CryptoDeposit+'.00'}</p>
                                </span>
                                <span className=" flex flex-col">
                                    <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">Profit</p>
                                    <p className=" font-Lato font-normal text-sm md:text-xl">{'$'+userData.CryptoProfits+'.00'}</p>
                                </span>
                            </div>
                        </div>
                    </span>
                </span>
                <span className=" mt-3 md:mt-0 absolute top-3 right-6 flex flex-row space-x-6 md:space-x-10">
                    <label htmlFor="" onClick={()=>{setDepoPopup(true)}} className=" flex flex-col items-center font-Lato font-semibold text-sm">
                        <button className=" bg-[#03AAC7] h-[32px] w-[32px] md:h-[45px] md:w-[45px] flex justify-center items-center mb-1 rounded-[50%]">
                            <img src={ imprt } alt="" />
                        </button>
                        Deposit
                    </label>
                    <label htmlFor="" onClick={()=>{setWithdrawPopup(true)}} className=" flex flex-col items-center font-Lato font-semibold text-sm">
                        <button className=" bg-[#03AAC7] h-[32px] w-[32px] md:h-[45px] md:w-[45px] flex justify-center items-center mb-1 rounded-[50%]">
                            <img src={ exprt } alt="" />
                        </button>
                        Withdraw
                    </label>
                </span>

                <div className="  border-t pt-3 w-full flex md:hidden border-[#EAEBF01A] flex-row justify-between ">
                    <span className=" md:ml-0 flex flex-col">
                        <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">Total balance</p>
                        <p className=" font-Lato font-normal text-sm md:text-xl">{'$'+userData.CryptoTotalbal+'.00'}</p>
                    </span>
                    <span className=" flex flex-col">
                        <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">Deposit</p>
                        <p className=" font-Lato font-normal text-sm md:text-xl">{'$'+userData.CryptoDeposit+'.00'}</p>
                    </span>
                    <span className=" flex flex-col">
                        <p className=" font-Lato font-normal text-[#FFFFFFCC] text-xs md:text-base">Profit</p>
                        <p className=" font-Lato font-normal text-sm md:text-xl">{'$'+userData.CryptoProfits+'.00'}</p>
                    </span>
                </div>
            </div>

            <div className=" w-full flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between mt-[1.5em]">
                <div className=" w-full md:w-1/3 p-4 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
                {exchangeSet1.map((exchangeSet1) => (
                    <table key={exchangeSet1.id} className="w-full">
                        <tbody>
                        <tr className=' font-Open-Sans font-normal text-sm text-center'>
                            <td>
                            <div className="crypto-info flex flex-row py-2 ml-2 space-x-2 items-center">
                                <img
                                src={exchangeSet1.image}
                                alt={`${exchangeSet1.name} logo`}
                                className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px] rounded-[50%]"
                                />
                                <p className="font-Open-Sans block md:hidden lg:block font-semibold uppercase">
                                {exchangeSet1.symbol}
                                </p>
                            </div>
                            </td>
                            <td className=" py-2 font-Open-Sans font-normal text-sm md:text-xs lg:text-sm ">${exchangeSet1.current_price}</td>
                            <td className={` ${getPriceChangeClass(exchangeSet1.price_change_percentage_24h)}  text-sm md:text-xs lg:text-sm`}>{exchangeSet1.price_change_percentage_24h}%</td>
                        </tr>
                        </tbody>
                    </table>
                    ))}
                </div>
                

                <div className=" w-full md:w-1/3 p-4 border rounded-[15px] md:mx-4 bg-[#1E1E1E33] border-[#EAEBF01A]">
                    {exchangeSet2.map((exchangeSet2) => (
                        <table key={exchangeSet2.id} className="w-full">
                            <tbody>
                            <tr className=' font-Open-Sans font-normal text-sm text-center'>
                                <td>
                                <div className="crypto-info flex flex-row py-2 ml-2 space-x-2 items-center">
                                    <img
                                    src={exchangeSet2.image}
                                    alt={`${exchangeSet2.name} logo`}
                                    className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px] rounded-[50%]"
                                    />
                                    <p className="font-Open-Sans  block md:hidden lg:block font-semibold uppercase text-sm">
                                    {exchangeSet2.symbol}
                                    </p>
                                </div>
                                </td>
                                <td className=" py-2 font-Open-Sans font-normal text-sm md:text-xs lg:text-sm ">${exchangeSet2.current_price}</td>
                                <td className={` ${getPriceChangeClass(exchangeSet2.price_change_percentage_24h)} text-sm md:text-xs lg:text-sm`}>{exchangeSet2.price_change_percentage_24h}%</td>
                            </tr>
                            </tbody>
                        </table>
                    ))}
                </div>

                <div className=" w-full md:w-1/3 p-4 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]">
                    {exchangeSet3.map((exchangeSet3) => (
                        <table key={exchangeSet3.id} className="w-full">
                            <tbody>
                            <tr className=' font-Open-Sans font-normal text-sm text-center'>
                                <td>
                                <div className="crypto-info flex flex-row py-2 ml-2 space-x-2 items-center">
                                    <img
                                    src={exchangeSet3.image}
                                    alt={`${exchangeSet3.name} logo`}
                                    className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px] rounded-[50%]"
                                    />
                                    <p className="font-Open-Sans  block md:hidden lg:block font-semibold uppercase text-sm">
                                    {exchangeSet3.symbol}
                                    </p>
                                </div>
                                </td>
                                <td className=" py-2 font-Open-Sans font-normal text-sm md:text-xs lg:text-sm ">${exchangeSet3.current_price}</td>
                                <td className={` ${getPriceChangeClass(exchangeSet3.price_change_percentage_24h)} text-sm md:text-xs lg:text-sm`}>{exchangeSet3.price_change_percentage_24h}%</td>
                            </tr>
                            </tbody>
                        </table>
                    ))}
                </div>
            </div>

            <div className='w-full mt-[1.5em] p-4 border rounded-[15px] bg-[#1E1E1E33] border-[#EAEBF01A]'>
                <table className=' w-[100%] font-Open-Sans text-sm text-white font-normal'>
                    <thead>
                        <tr className=' border-b border-[#EAEBF01A]'>
                            <th className=' text-left font-Lato font-medium text-xs md:text-sm py-5 pl-2'>Crypto</th>
                            <th className=' text-left font-Lato font-medium text-xs md:text-sm py-5 '>Current Price</th>
                            <th className=' text-left font-Lato font-medium text-xs md:text-sm py-5 md-max:hidden'>Low (24h)</th>
                            <th className=' text-left font-Lato font-medium text-xs md:text-sm py-5 md-max:hidden'>High (24h)</th>
                            <th className=' text-left font-Lato font-medium text-xs md:text-sm py-5 '>Price Change (24h)</th>
                            <th className=' text-left font-Lato font-medium text-xs md:text-sm py-5 '>Action</th>
                        </tr>
                    </thead>
                    <tbody className='font-Open-Sans text-sm text-white font-normal'>
                    {dataSet.map((crypto, index) => (
                        <tr className=' space-x- border-b border-[#EAEBF01A]' key={index}>
                        <td className=''>
                        <div className="crypto-info flex flex-row py-4 ml-2 space-x-2 items-center">
                            <img
                                src={crypto.image}
                                alt={`${crypto.name}`}
                                className=' w-[20px] h-[20px] lg:w-[32px] lg:h-[32px] rounded-[50%]'
                            />
                            <p className=' font-Open-Sans font-normal text-xs md:text-sm'>{crypto.name}</p>
                        </div>
                        </td>
                        <td className='font-Open-Sans text-xs md:text-sm py-4  text-white font-normal' >${crypto.current_price}</td>
                        <td className=' font-Open-Sans text-xs md:text-sm py-4 md-max:hidden text-white font-normal'>${crypto.low_24h}</td>
                        <td className=' font-Open-Sans text-xs md:text-sm py-4 md-max:hidden text-white font-normal'>${crypto.high_24h}</td>
                        <td className={`${getPriceChangeClass(crypto.price_change_percentage_24h)} font-Open-Sans text-xs md:text-base`}
>{crypto.price_change_percentage_24h}%</td>
                        <td className=''><button onClick={ getTradeinfo } className=' bg-[#03AAC7] rounded-[20px] px-4 py-2 font-Lato font-normal text-xs md:text-sm'>Trade</button></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </div>
        { depoPopup && <motion.div 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
            <div className='h-[85vh] '>
                <div className=' w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] '>
                    <div className=' flex flex-row justify-between border-b p-6 border-[#EAEBF01A]'>
                        <span className='flex flex-col'>
                            <p className=' font-Lato font-medium text-xl'>Deposit</p> 
                            <p className=' font-Lato font-normal text-xs text-[#FFFFFFCC]'>Send only to one of the following address</p> 
                        </span>
                        <img src={ close } onClick={()=>{setDepoPopup(false)}} alt="" />
                    </div>
                    <div className=' w-full px-6 md:px-6 pt-6 pb-3'>
                        <div className=' border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed'>
                            <input type="text" placeholder='$0.00' onKeyUp={(e)=>{setAmount(e.target.value)}} className=' w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]' />
                            <p className=' pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]'>Enter Amount</p>
                        </div>
                    </div>
                    <div className=' w-full px-6 md:px-6 space-y-2'>
                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ btc } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>BTC</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'bc1qj6wfphulvuexps3s3mv0wysfw40ng5gmkymyrg'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setCodeImage(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ eth } alt="" />
                                <p className=' font-Open-Sans text-sm md:text-sm font-normal'>ETH</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'0x59e4Ad35E1AF028282299b15fb1C5d0190BB1534'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setEthImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ doge } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>DOGE</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'DFZfKD5UztJQ9wkKFCg1otS22Hp14bg5Gm'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setDogeImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ usdt } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>USDT(TRC20)</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setUsdtImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ tron } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>Tron</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'TSQfdRch8w78zfgnacwzYSzEFwUSUW179Y'} className=' w-[90%] myInput text-[#FFFFFFCC] bg-transparent' />
                                <img src={ copy } onClick={ handleCopyClick } alt="" />
                            </div>
                            <button onClick={()=>setTronImg(true)} className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>QR Code</button>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ bt } alt="" />
                                <p className=' font-Open-Sans text-sm font-normal'>TFR</p>
                            </span>
                            <div className=' relative w-[94px] md:w-[60%] flex bg-[#1E1E1E] p-2 rounded-[30px]'>
                                <input type="text" readOnly value={'Contact support for assistance'} className=' w-[100%] text-[#FFFFFFCC] bg-transparent' />
                            </div>
                            <button className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>Support</button>
                        </div>
                    </div>
                    <div className=' py-3 w-full px-6 md:px-6'>
                        <div className='p-6 bg-[#1e1e1e] text-white w-full flex flex-col justify-center items-center rounded-[20px]'>
                            <button type="file" className=' w-[60px] h-[60px] rounded-[50%] bg-transparent flex justify-center items-center relative'>
                                <input type="file" onChange={(e)=>{setFile(e.target.files[0]); setNameFile(true)}} className=" w-[60px] h-[60px] cursor-pointer z-50 opacity-0" name="" id="" />
                                    <img src={ imgExport } className=" absolute z-10 top-3 left-[10px]" alt="" />
                            </button>
                            <p className=' font-Lato font-medium text-center text-base md:text-xl mt-1'>Click here to upload proof of payment</p>
                            {nameFile && <p className=' font-Lato font-medium text-xs md:text-sm text-[[#FFFFFFCC] mt-1'>{ imageName }</p>}
                        </div>
                    </div>
                    <div className=' w-full px-6 md:px-6 py-3'>
                        <button onClick={ sumbmitImg } className=' py-2 px-4 bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto block'>
                            { loading === 'No' && 'Submit'}
                            { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                        <p id='confirm' className=' ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]'>*Your transaction has been received and will be processed shortly</p>
                    </div>
                </div>
            </div>    
            </motion.div>}
        { withdrawPopup && <motion.div 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
            <div className='h-[85vh] '>
                <div className=' w-full md:w-[700px] lg:w-[700px] rounded-[30px] bg-[#2b2b2b] '>
                    <div className=' flex flex-row justify-between border-b p-6 border-[#EAEBF01A]'>
                        <span className='flex flex-col'>
                            <p className=' font-Lato font-medium text-xl'>Withdraw</p> 
                            <p className=' font-Lato font-normal text-xs text-[#FFFFFFCC]'>Please double-check the recipient's wallet address before withdrawing your funds.</p> 
                        </span>
                        <img src={ close } onClick={()=>{setWithdrawPopup(false)}} alt="" />
                    </div>
                    <div className=' w-full px-6 md:px-6 pt-6 pb-3'>
                        <div className=' border-[#EAEBF033] border rounded-[30px] flex flex-col pt-2 justify-center items-center border-dashed'>
                            <input type="text" placeholder='$0.00' onKeyUp={(e)=>{setWithdrawAmount(e.target.value)}} className=' w-[100px] text-center myInput font-Lato font-normal text-2xl placeholder:font-Lato placeholder:font-normal placeholder:text-center placeholder:text-2xl text-[#FFFFFF99] placeholder:text-[#FFFFFF99] bg-transparent h-[50px]' />
                            <p className=' pb-2 font-Open-Sans text-sm font-normal text-[#FFFFFFCC]'>Enter Amount</p>
                        </div>
                    </div>
                    
                    <div className=' w-full px-6 md:px-6 space-y-2'>
                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ btc } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>BTC</p>
                            </span>
                            <div className=' relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]'>
                                <input onKeyUp={(e)=>{setWalletAddy(e.target.value)}} type="text" placeholder='Paste wallet....' className=' font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent' />
                            </div>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ eth } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>ETH(BEP 20)</p>
                            </span>
                            <div className=' relative w-[60%] md:w-[80%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]'>
                                <input type="text" onKeyUp={(e)=>{setWalletAddy(e.target.value)}} placeholder='Paste wallet....' className=' font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent' />
                            </div>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ doge } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>DOGE</p>
                            </span>
                            <div className=' relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]'>
                                <input type="text" onKeyUp={(e)=>{setWalletAddy(e.target.value)}} placeholder='Paste wallet....' className=' font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent' />
                            </div>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ usdt } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>USDT</p>
                            </span>
                            <div className=' relative w-[73%] md:w-[85%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]'>
                                <input type="text" onKeyUp={(e)=>{setWalletAddy(e.target.value)}} placeholder='Paste wallet....' className=' font-Open-Sans text-base font-normal placeholder:font-Open-Sans placeholder:text-[#ffffffcc] w-[100%] myInput text-[#FFFFFFCC] bg-transparent' />
                            </div>
                        </div>

                        <div className=' w-full border border-[#EAEBF033] p-3 rounded-[40px] flex items-center justify-between'>
                            <span className=' flex items-center space-x-2'>
                                <img src={ bt } alt="" />
                                <p className=' font-Open-Sans text-xs md:text-sm font-normal'>TFR</p>
                            </span>
                            <div className=' relative w-[50%] md:w-[70%] flex bg-[#1E1E1E] py-2 px-3 rounded-[30px]'>
                                <input type="text" readOnly value={'Contact support for assistance'} className=' w-[100%] text-[#FFFFFFCC] bg-transparent' />
                            </div>
                            <button className=' text-center bg-[#03AAC7] py-2 px-2 font-Lato text-xs font-normal rounded-[20px]'>Support</button>
                        </div>
                    </div>
                    <div className=' w-full px-6 md:px-6 py-3'>
                        <button onClick={ submitWithdrawal } className=' w-full flex justify-center items-center h-[45px] bg-[#03AAC7] font-Lato font-semibold text-center rounded-[30px] ml-auto'>
                            { loading === 'No' && 'Process Withdrawal'}
                            { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                        <p id='withdraw' className=' ml-auto w-full text-right hidden text-[8px] md:text-xs mt-2 font-Nunito font-medium text-[#0CD16B]'>*Your transaction has been received and will be processed shortly</p>
                    </div>
                </div>
            </div>    
            </motion.div>}
            { lowBalance && <div className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
                <div className=' px-10 py-8 bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center'>
                    <img src={ close } onClick={()=>{setLowBalance(false)}} className=' absolute right-4 top-3' alt="" />
                    <img src={ danger } alt="" />
                    <p className=' mt-3 text-center font-Lato font-medium text-2xl '>Insufficient balance</p>
                    <p className=' mt-3 text-center font-Lato font-normal text-base w-[240px]'>You do not have enough funds in your wallet to complete this withdrawal. Please deposit more funds or reduce the amount you are withdrawing.</p>
                </div>
            </div>}
            { upgrade && <div className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
                <div className=' px-10 py-8 bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center'>
                    <img src={ close } onClick={()=>{setUpgrade(false)}} className=' absolute right-4 top-3' alt="" />
                    <img src={ warner } alt="" />
                    <p className=' mt-3 text-center font-Lato font-medium text-2xl '>Commision Fee</p>
                    <p className=' mt-3 text-center font-Lato font-normal text-base w-[240px]'>To withdraw funds, you need to  pay a commision fee.</p>
                </div>
            </div>}
            {  tradePopup && <div className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
                <div className=''>
                    <div className=' w-full md:w-[700px] lg:w-[700px] rounded-[30px] text-white bg-[#2b2b2b] relative p-6'>
                        <img src={ close } onClick={()=>{setTradePopup(false)}} className=' absolute right-4 top-3' alt="" />
                        <p className=' font-Lato font-semibold text-xl'>{ tradeName }</p>
                        <div className=' flex flex-row items-center mt-3 space-x-6 md:space-x-8'>
                            <span className=' flex space-x-1'>
                                <img src={ twoUser } alt="" />
                                <p className='font-Open-Sans font-normal text-sm text-[#FFFFFFCC]'>9</p>
                            </span>
                            <span className=' flex space-x-1'>
                                <p className='font-Open-Sans font-normal text-sm text-[#FFFFFF]'>ROI:</p>
                                <p className='font-Open-Sans font-normal text-sm text-[#30B278]'>9%</p>
                            </span>
                            <span className=' flex space-x-1'>
                                <p className='font-Open-Sans font-normal text-sm text-[#FFFFFF99]'>Min. Investment:</p>
                                <p className='font-Open-Sans font-normal text-sm text-[#fff]'>$100</p>
                            </span>
                        </div>
                        <div className=' mt-2'>
                            <label className=" font-Open-Sans text-sm text-left w-full font-normal" for="Amount">Amount</label>
                            <input type="text" onKeyUp={(e)=>{setTradeAmount(e.target.value)}} placeholder="Enter Amount" class=" h-[45px] w-full mt-1 bg-[#1e1e1e] text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[30px] block p-2.5"/>

                            <label className=" font-Open-Sans text-sm text-left mt-3 w-full font-normal" for="Amount">Runtime</label>
                            <span class=" h-[45px] w-full mt-1 bg-[#1e1e1e] text-white font-Open-Sans font-normal text-sm rounded-[30px] flex justify-between p-2.5">
                                <img src={ arrLeft } onClick={ handleDecrease } alt="" />
                                <input type="text" placeholder='0 days' value={`${count} days`} className=' bg-transparent w-[120px] h-[45px] -mt-2.5 text-center myInput font-Open-Sans font-normal text-sm text-[#FFFFFFCC] placeholder:font-Open-Sans placeholder:font-normal placeholder:text-sm' />
                                <img src={ arrRight } onClick={ handleIncrease } alt="" />
                            </span>
                            { lowBalTrade && <p className=' text-center text-xs font-Nunito-Sans mt-1 text-[#F13645] font-medium'>Insufficient Balance, Please top up your balance</p>}
                        </div>
                        <button onClick={ tradeTRans } className=' mt-6 w-full h-[45px] flex justify-center items-center rounded-[30px] bg-[#03AAC7] text-center font-lato font-semibold text-lg'>
                            { loading === 'No' && 'Trade'}
                            { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                    </div>
                </div>
            </div>}
            { completePopup && <div className='w-[100%] h-[100vh] bg-[rgba(0,0,0,0.5)] z-[999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5 overflow-y-auto'>
                <div className=' w-[300px] h-[250px] bg-[#121212] rounded-[20px] justify-center flex flex-col relative items-center'>
                    <img src={ close } onClick={()=>{setCompletePopup(false)}} className=' absolute right-4 top-3' alt="" />
                    <img src={ tickCircle } alt="" />
                    <p className=' mt-3 text-center font-Lato font-medium text-2xl px-10'>Trade Successful Created</p>
                </div>
            </div>}
        </>
     );
}
 
export default CryptoDash;